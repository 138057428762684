<template>
  <div class="login_box" v-if="platformInfo.login_page == 1">
    <div class="content_box">
      <div class="left_img">
        <img src="@/assets/images/login.png" alt="">
      </div>
      <div class="right">
        <div class="img_box">
          <img :src="platformInfo.logo_url" alt="">
        </div>
        <div class="title">{{ platformInfo.platform_name }}</div>
        <el-form :model="LoginForm" ref="LoginFormRef" :rules="LoginFormRules" class="login_form">
          <el-form-item prop="username">
            <el-input placeholder="用户名" prefix-icon="iconfont lebo-zhanghao" maxlength="15" v-model="LoginForm.username"
              @input="(e) => (LoginForm.username = validSpace(e))" @blur="usernameBlur"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input placeholder="密码" prefix-icon="iconfont lebo-mima" :type="isNewPassWord ? 'text' : 'password'"
              v-model="LoginForm.password" @input="(e) => (LoginForm.password = validSpacing(e))"
              @keyup.enter.native="onLogin">
              <i slot="suffix" class="suffix_psd" @click="isNewPassWord = !isNewPassWord;"
                :class="'iconfont ' + (isNewPassWord ? 'lebo-zhengyanicon' : 'lebo-biyan')" v-if="LoginForm.password"></i>
            </el-input>
          </el-form-item>
          <el-form-item class="code_input_box" v-if=" errCount >= 3 || psdCount >= 3 " prop="VerificationCode">
            <el-input placeholder="验证码" v-model=" LoginForm.VerificationCode "
              @input=" (e) => (LoginForm.VerificationCode = validSpacing(e)) " @keyup.enter.native=" onLogin ">
            </el-input>
            <img :src=" imgCode " alt="" class="imgCode_box" />
            <a class="checkout_text" @click=" imgClick ">换一张</a>
          </el-form-item>
          <el-form-item class="btn_item">
            <el-button :disabled=" isDisabled " @click=" onLogin ">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
  <!-- <div class="login_box_v4" v-else-if="platformInfo.login_page == 2">
    <div class="content_box">
      <div class="title">{{ platformInfo.platform_name }}</div>
      <el-form :model="LoginForm" ref="LoginFormRef" :rules="LoginFormRules" class="login_form">
        <el-form-item prop="username">
          <el-input placeholder="用户名" prefix-icon="iconfont lebo-zhanghao" maxlength="15" v-model="LoginForm.username" @input="(e) => (LoginForm.username = validSpace(e))" @blur="usernameBlur"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input placeholder="密码" prefix-icon="iconfont lebo-mima" :type="isNewPassWord ? 'text' : 'password'" v-model="LoginForm.password" @input="(e) => (LoginForm.password = validSpacing(e))" @keyup.enter.native="onLogin">
            <i slot="suffix" class="suffix_psd" @click="isNewPassWord = !isNewPassWord;" :class="'iconfont ' + (isNewPassWord ? 'lebo-zhengyanicon' : 'lebo-biyan')" v-if="LoginForm.password"></i>
          </el-input>
        </el-form-item>
        <el-form-item class="code_input_box" v-if="errCount >= 3 || psdCount >= 3">
          <el-input placeholder="验证码" v-model="LoginForm.VerificationCode" @input="(e) => (LoginForm.VerificationCode = validSpacing(e))" @keyup.enter.native="onLogin">
          </el-input>
          <img :src="imgCode" alt="" class="imgCode_box" />
          <a class="checkout_text" @click="imgClick">换一张</a>
        </el-form-item>
        <el-form-item class="btn_item">
          <el-button :disabled="isDisabled" @click="onLogin">登录</el-button>
        </el-form-item>
      </el-form>
      <div class="img_box">
        <img :src="platformInfo.logo_url" alt="">
      </div>
    </div>
  </div> -->
  <div class="login_box_v2" v-else-if=" platformInfo.login_page == 2 ">
    <div class="content_box">
      <div class="left_img">
        <img src="@/assets/images/login-v2.png" alt="">
      </div>
      <div class="right">
        <div class="title">{{ platformInfo.platform_name }}</div>
        <el-form :model=" LoginForm " ref="LoginFormRef" :rules=" LoginFormRules " class="login_form">
          <el-form-item prop="username">
            <el-input placeholder="用户名" prefix-icon="iconfont lebo-zhanghao" maxlength="15" v-model=" LoginForm.username "
              @input=" (e) => (LoginForm.username = validSpace(e)) " @blur=" usernameBlur "></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input placeholder="密码" prefix-icon="iconfont lebo-mima" :type=" isNewPassWord ? 'text' : 'password' "
              v-model=" LoginForm.password " @input=" (e) => (LoginForm.password = validSpacing(e)) "
              @keyup.enter.native=" onLogin ">
              <i slot="suffix" class="suffix_psd" @click=" isNewPassWord = !isNewPassWord; "
                :class=" 'iconfont ' + (isNewPassWord ? 'lebo-zhengyanicon' : 'lebo-biyan') "
                v-if=" LoginForm.password "></i>
            </el-input>
          </el-form-item>
          <el-form-item class="code_input_box" v-if=" errCount >= 3 || psdCount >= 3 " prop="VerificationCode">
            <el-input placeholder="验证码" v-model=" LoginForm.VerificationCode "
              @input=" (e) => (LoginForm.VerificationCode = validSpacing(e)) " @keyup.enter.native=" onLogin ">
            </el-input>
            <img :src=" imgCode " alt="" class="imgCode_box" />
            <a class="checkout_text" @click=" imgClick ">换一张</a>
          </el-form-item>
          <el-form-item class="btn_item">
            <el-button :disabled=" isDisabled " @click=" onLogin ">登录</el-button>
          </el-form-item>
        </el-form>
        <div class="img_box">
          <img :src=" platformInfo.logo_url " alt="">
        </div>
        <div class="img_box_icon">
          <img src="@/assets/images/login_bg_icon_v2.png" alt="">
        </div>
      </div>
    </div>
  </div>
  <div class="login_box_v3" v-else-if=" platformInfo.login_page == 3 ">
    <div class="content_box">
      <div class="left_img">
        <div class="img_box">
          <img :src=" platformInfo.logo_url " alt="">
        </div>
        <img src="@/assets/images/login-v3.png" alt="">
      </div>
      <div class="right">
        <div class="title">{{ platformInfo.platform_name }}</div>
        <el-form :model=" LoginForm " ref="LoginFormRef" :rules=" LoginFormRules " class="login_form">
          <el-form-item prop="username">
            <el-input placeholder="用户名" prefix-icon="iconfont lebo-zhanghao" maxlength="15" v-model=" LoginForm.username "
              @input=" (e) => (LoginForm.username = validSpace(e)) " @blur=" usernameBlur "></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input placeholder="密码" prefix-icon="iconfont lebo-mima" :type=" isNewPassWord ? 'text' : 'password' "
              v-model=" LoginForm.password " @input=" (e) => (LoginForm.password = validSpacing(e)) "
              @keyup.enter.native=" onLogin ">
              <i slot="suffix" class="suffix_psd" @click=" isNewPassWord = !isNewPassWord; "
                :class=" 'iconfont ' + (isNewPassWord ? 'lebo-zhengyanicon' : 'lebo-biyan') "
                v-if=" LoginForm.password "></i>
            </el-input>
          </el-form-item>
          <el-form-item class="code_input_box" v-if=" errCount >= 3 || psdCount >= 3 " prop="VerificationCode">
            <el-input placeholder="验证码" v-model=" LoginForm.VerificationCode "
              @input=" (e) => (LoginForm.VerificationCode = validSpacing(e)) " @keyup.enter.native=" onLogin ">
            </el-input>
            <img :src=" imgCode " alt="" class="imgCode_box" />
            <a class="checkout_text" @click=" imgClick ">换一张</a>
          </el-form-item>
          <el-form-item class="btn_item">
            <el-button :disabled=" isDisabled " @click=" onLogin ">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
  <div class="login_box_v4" v-else-if=" platformInfo.login_page == 4 ">
    <div class="backGroundBox"></div>
    <div class="content_box">
      <div class="left_img">
        <!-- <div class="img_box">
          <img :src="platformInfo.logo_url" alt="">
        </div> -->
        <!-- <img src="../assets/images/login-v3.png" alt=""> -->
      </div>
      <div class="right">
        <div class="title">{{ platformInfo.platform_name }}</div>
        <el-form :model=" LoginForm " ref="LoginFormRef" :rules=" LoginFormRules " class="login_form">
          <el-form-item prop="username">
            <el-input placeholder="用户名" prefix-icon="iconfont lebo-zhanghao" maxlength="15" v-model=" LoginForm.username "
              @input=" (e) => (LoginForm.username = validSpace(e)) " @blur=" usernameBlur "></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input placeholder="密码" prefix-icon="iconfont lebo-mima" :type=" isNewPassWord ? 'text' : 'password' "
              v-model=" LoginForm.password " @input=" (e) => (LoginForm.password = validSpacing(e)) "
              @keyup.enter.native=" onLogin ">
              <i slot="suffix" class="suffix_psd" @click=" isNewPassWord = !isNewPassWord; "
                :class=" 'iconfont ' + (isNewPassWord ? 'lebo-zhengyanicon' : 'lebo-biyan') "
                v-if=" LoginForm.password "></i>
            </el-input>
          </el-form-item>
          <el-form-item class="code_input_box" v-if=" errCount >= 3 || psdCount >= 3 " prop="VerificationCode">
            <el-input placeholder="验证码" v-model=" LoginForm.VerificationCode "
              @input=" (e) => (LoginForm.VerificationCode = validSpacing(e)) " @keyup.enter.native=" onLogin ">
            </el-input>
            <img :src=" imgCode " alt="" class="imgCode_box" />
            <a class="checkout_text" @click=" imgClick ">换一张</a>
          </el-form-item>
          <el-form-item class="btn_item">
            <el-button :disabled=" isDisabled " @click=" onLogin ">登 录</el-button>
          </el-form-item>
          <div class="img_box">
            <img :src=" platformInfo.logo_url " alt="">
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { login, getSelDefault, getLoginPageInfoByDomainAddr } from '@/api/userapi'
// import API_CONFIG from '../../vue.config.js'
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'Login',
  data () {
    // 自定义验证规则  用户密码
    var psdRule = (rule, value, callback) => {
      const psdReg = /^[\w]{8,14}$/
      if (psdReg.test(value)) {
        return callback()
      }
      callback(new Error())
    }
    return {
      // 当前登录页主题信息
      // platformInfo: {},
      // login_page: 1,
      // logo_url: '',
      // login_title: '',
      isNewPassWord: false,
      // 密码错误次数
      psdCount: 0,
      errCount: 0,
      // 表单数据双向绑定
      LoginForm: {
        username: '',
        password: '',
        VerificationCode: ''
      },
      imgCode: '',
      wsValue: null,
      isDisabled: false,
      // 表单数据验证规则
      LoginFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
          // {
          //   min: 3,
          //   max: 10,
          //   message: "长度在 3 到 10 个字符",
          //   trigger: "blur",
          // },
        ],
        password: [
          { required: true, message: '请输入用户密码', trigger: 'blur' },
          {
            validator: psdRule,
            message: ' 8~14个字符，包含字母、数字，下划线，区分大小写',
            trigger: 'blur'
          }
          // 8~14个字符，包含字母、数字，区分大小写
        ],
        VerificationCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    console.log('登录页面信息', JSON.stringify(this.platformInfo))
    // this.platformInfo = this.platformInfo;
    // this.fngetLoginPageInfoByDomainAddr()
    // console.log(this.getQueryString("access_toke"))
    // if (this.getQueryString("access_toke")) {
    //   sessionStorage.setItem('token', this.getQueryString("access_toke"))
    //   sessionStorage.setItem('refreshtoken', this.getQueryString("refresh_token"))
    //   this.setScene(true)
    //   this.setDomainAddress(window.location.hostname)
    //   this.$store.dispatch('menuList/fngetGroupBigScreen');
    //   setTimeout(() => {
    //     this.$store.dispatch('menuList/requestMenus');
    //     this.$store.dispatch('requestPorkingList');
    //     this.$store.dispatch('requestFindList');
    //     this.$store.dispatch('requestUserInfo');
    //     this.$store.dispatch('requestAlarmCount');
    //     this.$router.push('/home')
    //   }, 200)
    // } else {
    // this.setScene(false)
    this.psdCount = localStorage.getItem('psdCount')
    this.errCount = localStorage.getItem('errNum')
    if (this.errCount >= 3 || this.psdCount >= 3) {
      this.imgClick()
    }
    // this.log_out();
    sessionStorage.removeItem('token')
    // }
    // 根据域名获取集团登录页信息
    // 回车登录
    // var that = this;
    // document.onkeydown = function (e) {
    //   e = window.event || e;
    // console.log(e);
    //   if (that.$route.path === '/login' && (e.code === 'Enter' || e.code === 'enter')) { // 验证在登录界面和按得键是回车键enter
    //     that.onLogin(); // 登录操作
    //   }
    // }
  },
  mounted () {
    // this.wsValue = this.socketConnect('ws://localhost:45678');
  },
  computed: {
    ...mapState(['platformInfo'])
  },
  methods: {
    ...mapMutations(['log_out']),
    ...mapMutations('menuList', [
      'setScene',
      'setDomainAddress'
    ]),
    // // 根据域名获取集团登录页信息
    // async fngetLoginPageInfoByDomainAddr() {
    //   var domain_address = window.location.hostname === 'localhost' ? 'tcity.lebopark.com' : window.location.hostname;
    //   const res = await getLoginPageInfoByDomainAddr({
    //     // domain_address: 'tcity.leboparkzz.api.com'
    //     domain_address: domain_address,
    //   })
    //   if (res && res.Code === 200 && res.Data) {
    //     var data = res.Data
    //     this.login_page = data.login_page || 1
    //     this.platformInfo.logo_url = data.logo_url || this.platformInfo.logo_url;
    //     this.platformInfo.login_title = data.platform_name || this.platformInfo.login_title;
    //     this.setplatformInfo({
    //       logo_url: this.platformInfo.logo_url,
    //       login_title: this.platformInfo.login_title,
    //       logo_url: this.platformInfo.logo_url,
    //       platform_name: this.platformInfo.login_title
    //     });
    //   }
    // },
    getQueryString (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    },
    // 登录
    onLogin () {
      // if (this.wsValue !== null) {
      // console.log("发送人脸比对请求")
      //   this.wsValue.send("open")
      // }
      // 对整个表单进行校验
      this.$refs.LoginFormRef.validate(async (valid) => {
        // 成功
        if (valid) {
          this.isDisabled = true
          const obj = {
            client_id: 'parkinglot',
            client_secret: 'secret',
            grant_type: 'password',
            scope: 'offline_access openid parkinglot',
            username: this.LoginForm.username,
            password: this.LoginForm.password,
            // VerificationCode: Number(this.LoginForm.VerificationCode),
            LoginSource: 1
          }
          if (this.LoginForm.VerificationCode) {
            obj.VerificationCode = this.LoginForm.VerificationCode
          }
          const res = await login(obj)
          // console.log(res);
          if (res) {
            localStorage.removeItem('psdCount')
            localStorage.removeItem('errNum')
            sessionStorage.setItem('token', res.access_token)
            sessionStorage.setItem('refreshtoken', res.refresh_token)
            sessionStorage.setItem('overexpirestime', `${new Date().getTime() + res.expires_in * 1000}`)

            this.isDisabled = false
            var that = this
            this.$store.dispatch('menuList/fngetGroupBigScreen')
            setTimeout(() => {
              that.$store.dispatch('menuList/requestMenus')
              that.$store.dispatch('requestPorkingList')
              that.$store.dispatch('requestFindList')
              that.$store.dispatch('requestUserInfo')
              that.$store.dispatch('requestAlarmCount')
              that.$router.push('/home')
            }, 200)
          } else {
            this.psdCount++
            this.errCount = localStorage.getItem('errNum')
            if (this.errCount >= 3 || this.psdCount >= 3) {
              this.imgClick()
            }
            localStorage.setItem('psdCount', this.psdCount)
            var _this = this
            setTimeout(() => {
              _this.isDisabled = false
            }, 3000)
          }
        } else {
          return false
        }
      })
    },
    usernameBlur () {
      if (this.psdCount >= 3 && this.errCount >= 3) {
        this.imgClick()
      }
    },
    // 刷新图形验证码
    imgClick () {
      var date = new Date().getTime()
      this.imgCode = window.location.href.split('/webmanage')[0] + `/api/Utility/VerificationCode/GetVerificationCodeByPC?username=${this.LoginForm.username ? this.LoginForm.username : Math.random()}&date=${date}`
      console.log('验证码', this.imgCode)
    }
    // socketConnect(url) {
    //   // 客户端与服务器进行连接
    //   const ws = new WebSocket(url); // 返回`WebSocket`对象，赋值给变量ws
    //   // 连接成功回调
    //   ws.onopen = e => {
    //     // console.log('连接成功', e)
    //     ws.send('我发送消息给服务端'); // 客户端与服务器端通信
    //   }
    //   // 监听服务器端返回的信息
    //   ws.onmessage = e => {
    //     // console.log('服务器端返回：', e.data);
    //   }
    //   return ws; // 返回websocket对象
    // }
  }
}
</script>
<style scoped lang="less">
.login_box {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/login-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content_box {
    width: 1180px;
    height: 587px;
    background-image: url("~@/assets/images/login-kuang.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .left_img {
      width: 610px;
      object-fit: contain;
      margin: 0 75px;

      img {
        width: 100%;
      }
    }

    .right {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .img_box {
        height: 25px;
        max-width: 80%;

        >img {
          max-width: 100%;
          height: 100%;
        }
      }

      .title {
        margin: 30px 0;
        font-size: 30px;
        color: #ffffff;
      }

      /deep/ .login_form {
        width: 360px;

        .el-form-item {

          .el-input__inner,
          .el-input {
            width: 100%;
            height: 50px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 5px;
            box-sizing: border-box;
            color: #ffffff;
            border: none;
            caret-color: #ffffff; //css3属性

            .el-input__prefix,
            .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .iconfont {
            font-size: 20px !important;
            color: #ffffff;
          }

          .suffix_psd {
            cursor: pointer;
          }
        }

        .code_input_box {
          .el-form-item__content {
            width: 100%;
            display: flex;
            align-items: center;

            .el-input__inner,
            .el-input {
              width: 200px;
              height: 50px;
            }

            .imgCode_box {
              width: 85px;
              height: 38px;
              margin-left: 23px;
              margin-right: 10px;
            }

            .checkout_text {
              cursor: pointer;
              font-size: 14px;
              color: #fff;
            }
          }
        }

        .btn_item {
          margin-bottom: 0;

          .el-button {
            width: 100%;
            height: 40px;
            background-color: #01a6fb;
            box-shadow: 0px 5px 10px 0px rgba(1, 166, 251, 0.3);
            border-radius: 5px;
            border: none;
            color: #fff;
          }
        }
      }
    }
  }

  /deep/ input:-webkit-autofill {
    /*填充样式效果取消*/
    background: none !important;
    -webkit-text-fill-color: #fff !important;
    transition: background-color 99999s ease-in-out 0s;
    -webkit-transition-delay: 99999s;
  }
}

// 第二版登录样式
.login_box_v2 {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/login-bg-v2.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  background-color: #171d2f;
  display: flex;
  align-items: center;
  justify-content: center;

  .content_box {
    width: 1180px;
    height: 587px;
    // margin-left: 25%;
    // background-image: url("../assets/images/login-kuang.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .left_img {
      width: 610px;
      object-fit: contain;
      // margin: 0 75px;

      img {
        width: 100%;
      }
    }

    .right {
      width: 40%;
      display: flex;
      flex-direction: column;
      //  justify-content: flex-start;
      justify-content: center;
      align-items: center;

      .img_box {
        margin-top: 60px;
        height: 34px;
        width: 86px;
        max-width: 80%;

        >img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .img_box_icon {
        // margin-top: 10px;
        height: 60px;
        width: 100%;

        >img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .title {
        /* 将背景设为渐变 */
        background-image: -webkit-linear-gradient(top, white, #0fd3ff);
        /* 规定背景绘制区域 */
        -webkit-background-clip: text;
        /* 将文字隐藏 */
        -webkit-text-fill-color: transparent;
        // margin: 30px 0;
        margin-bottom: 30px;
        font-size: 28px;
        font-weight: bold;
      }

      /deep/ .login_form {
        width: 360px;

        .el-form-item {

          .el-input__inner,
          .el-input {
            width: 100%;
            height: 40px;
            background-color: rgba(101, 131, 161, 0.2);
            border-radius: 5px;
            box-sizing: border-box;
            color: #ffffff;
            border: none;
            caret-color: #ffffff; //css3属性

            .el-input__prefix,
            .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .iconfont {
            font-size: 20px !important;
            color: #ffffff;
          }

          .suffix_psd {
            cursor: pointer;
          }
        }

        .code_input_box {
          .el-form-item__content {
            width: 100%;
            display: flex;
            align-items: center;

            .el-input__inner,
            .el-input {
              width: 200px;
              height: 50px;
            }

            .imgCode_box {
              width: 85px;
              height: 38px;
              margin-left: 23px;
              margin-right: 10px;
            }

            .checkout_text {
              cursor: pointer;
              font-size: 14px;
              color: #fff;
            }
          }
        }

        .btn_item {
          margin-bottom: 0;

          .el-button {
            width: 100%;
            height: 40px;
            background-color: #5395ef;
            box-shadow: 0px 5px 10px 0px rgba(15, 212, 255, 0.3);
            border-radius: 5px;
            border: none;
            color: #fff;
          }
        }
      }
    }
  }

  /deep/ input:-webkit-autofill {
    /*填充样式效果取消*/
    background: none !important;
    -webkit-text-fill-color: #fff !important;
    transition: background-color 99999s ease-in-out 0s;
    -webkit-transition-delay: 99999s;
  }
}

.login_box_v3 {
  width: 100%;
  height: 100%;
  // background-image: url("../assets/images/login-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(236, 245, 252, 1);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content_box {
    width: 1001px;
    height: 600px;
    background: #ffffff;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);
    // background-image: url("../assets/images/login-kuang.png");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .left_img {
      width: 466px;
      height: 396px;
      object-fit: contain;
      margin: 0 50px;

      img {
        width: 100%;
      }

      .img_box {
        margin-left: 100px;
        margin-bottom: 30px;
        height: 40px;
        max-width: 200px;

        >img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .title {
        margin-top: 10px;
        margin-bottom: 50px;
        font-size: 26px;
        color: #666;
      }

      /deep/ .login_form {
        width: 360px;

        .el-form-item {

          .el-input__inner,
          .el-input {
            width: 100%;
            height: 50px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 5px;
            box-sizing: border-box;
            color: #000;
            caret-color: #000; //css3属性

            .el-input__prefix,
            .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .iconfont {
            font-size: 20px !important;
            color: #999;
          }

          .suffix_psd {
            cursor: pointer;
          }
        }

        .code_input_box {
          .el-form-item__content {
            width: 100%;
            display: flex;
            align-items: center;

            .el-input__inner,
            .el-input {
              width: 200px;
              height: 50px;
            }

            .imgCode_box {
              width: 85px;
              height: 38px;
              margin-left: 23px;
              margin-right: 10px;
            }

            .checkout_text {
              cursor: pointer;
              font-size: 14px;
              color: #000;
            }
          }
        }

        .btn_item {
          margin-bottom: 0;

          .el-button {
            width: 100%;
            height: 50px;
            background-color: #01a6fb;
            box-shadow: 0px 5px 10px 0px rgba(1, 166, 251, 0.3);
            border-radius: 5px;
            border: none;
            color: #fff;
          }
        }
      }
    }
  }

  /deep/ input:-webkit-autofill {
    /*填充样式效果取消*/
    background: none !important;
    -webkit-text-fill-color: #000 !important;
    transition: background-color 99999s ease-in-out 0s;
    -webkit-transition-delay: 99999s;
  }
}

.login_box_v4 {
  width: 100%;
  height: 100%;
  position: relative;
  .backGroundBox {
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/images/login-bg03.png");
    background-repeat: no-repeat;
    background-position: center;
    // background-color: rgba(236, 245, 252, 1);
    background-size: 100% 100%;
    display: flex;
    align-items: center;
  }

  .content_box {
    position: absolute;
    top: 120px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    .right {
      .title {
        margin-top: 10px;
        margin-bottom: 50px;
        font-size: 26px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        color: #666;
        font-size: 40px;
      }

      .btn_item {
        .el-button {
          border-radius: 8px;
          font-size: 20px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
  }

  /deep/ .content_box {
    width: 550px;
    height: 650px;
    margin: auto 0;
    margin-left: 190px;
    padding: 80px 60px;
    box-sizing: border-box;
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(1, 166, 251, 0.1) 0%,
        rgba(255, 255, 255, 0.17) 33%,
        rgba(255, 255, 255, 0.1) 100%);
    border-radius: 20px;
    box-shadow: 0px 0px 10px 5px rgba(1, 166, 251, 0.1);

    .title {
      font-size: 30px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      text-align: left;
      margin-bottom: 40px;
    }

    .el-form-item {
      margin-bottom: 40px;

      .el-input__inner,
      .el-input {
        width: 100%;
        height: 50px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 10px;
        box-sizing: border-box;
        color: #333333;
        border: none;
        caret-color: #333333; //css3属性

        .el-input__prefix,
        .el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #999999;
        }
      }

      .iconfont {
        font-size: 20px !important;
        color: #999999;
      }

      .suffix_psd {
        cursor: pointer;
      }
    }

    .code_input_box {
      .el-form-item__content {
        width: 100%;
        display: flex;
        align-items: center;

        .el-input__inner,
        .el-input {
          width: 250px;
          height: 50px;
        }

        .imgCode_box {
          width: 85px;
          height: 38px;
          margin-left: 23px;
          margin-right: 10px;
        }

        .checkout_text {
          cursor: pointer;
          font-size: 14px;
          color: #01a6fb;
        }
      }
    }

    .btn_item {
      margin-bottom: 40px;

      .el-button {
        width: 100%;
        height: 50px;
        background-color: #01a6fb;
        box-shadow: 0px 5px 10px 0px rgba(1, 166, 251, 0.3);
        border-radius: 5px;
        border: none;
        color: #fff;
      }
    }

    .img_box {
      height: 25px;

      >img {
        height: 100%;
      }
    }
  }
}</style>
